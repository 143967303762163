.form {
	display: flex;
	flex-direction: column;
	label {display: flex}
	
	input:not([type='checkbox']):not([type="file"]), textarea {
		width: 100%;
		margin-top: 40px;
		padding: 10px 15px;
		background: none;
		border: none;
		border-bottom: solid 1px $color-secondary;
		&:focus {
			border-bottom: solid 1px $color-primary;
			outline: none
		}
	}
	.widget {
		margin-top: 10px;
		position: relative;
	}
	.widget-select {
		display: flex;
		select {
			padding: 20px;
			width: 100%;
		}
	}
}

//Floating Labels
.form__floating {
	input:not([type="checkbox"]):not([type="hidden"]) + label {
		position: absolute;
		bottom: 0;
		transform: translate(15px, 0);
		transition: transform 0.2s;
	}
	input:not([type=checkbox]).active + label {
		transform: translate(0, -30px) scale(0.9);
		transition: transform 0.2s;
		color: grey
	}
}

option{
	box-shadow: 0 0 10px 100px #FED20F inset;
	transition: all .2s ease-in-out;
}


input, textarea {
	border: solid 0.5px #a9a9a9;
	background: #fff;
	padding: 10px;
}

//Checkbox

.checkbox_container input[type=checkbox] {
	position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
}

.checkbox_container label {
	position: relative;
	padding-left: 30px;
	font-size: 0.7rem;
}

.checkbox_container span {
	position: absolute;
	display: flex;
	align-items: center;
    justify-content: center;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: solid 1px $color-primary; 
}


.checkbox_container i {
	display: none
}

.checkbox_container input:checked ~ span i {
	display: inline-block;
	font-size: 1.1rem;
} 