.privacy-quicklink {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: #fff;
  border-radius: 50%;
  padding: 15px;
  z-index: 9999;
  cursor: pointer;
  &__inner {
    display: flex;
  }
}