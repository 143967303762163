.ce_rsce_horizontal_boxes {
  width: 100%;

  .accordion-horizontal-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    overflow: hidden;
    list-style-type: none;
    max-height: 100vh;
    margin-bottom: 0;

    @media only screen and (max-width:1200px) {
      margin-bottom: 0.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &:not(:hover) .accordion_horizontal_container:first-child,
    .accordion_horizontal_container:focus,
    .accordion_horizontal_container:hover {
      -webkit-box-flex: 5;
      -ms-flex: 5;
      flex: 5;
      cursor: default;
    }

    &:not(:hover) .accordion_horizontal_container:first-child:before,
    .accordion_horizontal_container:focus:before,
    .accordion_horizontal_container:hover:before {
      opacity: 0.8;
      background-color: $color-primary;
    }

    &:not(:hover) .accordion_horizontal_container:first-child .accordion-horizontal-toggle,
    .accordion_horizontal_container:focus .accordion-horizontal-toggle,
    .accordion_horizontal_container:hover .accordion-horizontal-toggle {
      opacity: 0;
      pointer-events: none;
    }

    &:not(:hover) .accordion_horizontal_container:first-child .ce_text,
    .accordion_horizontal_container:focus .ce_text,
    .accordion_horizontal_container:hover .ce_text {
      opacity: 1;
      transition-delay: 200ms;
      transition-duration: 250ms;
      transition-property: opacity;
      transition-timing-function: ease-in;
    }

    .accordion_horizontal_container {
      flex: 1;
      display: flex;
      align-items: stretch;
      transition: all 450ms ease;
      position: relative;
      min-height: 600px;
      background-size: cover;
      background-position: center;
      margin-bottom: 0px;
      color: #fff;

      @media only screen and (max-width:1200px) {
        max-width: 70%;
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 auto !important;
        flex: 0 0 auto !important;
      }

      @media only screen and (max-width:767px) {
        min-height: 400px;
      }


      @media only screen and (max-width:1200px) and (max-width:540px) {
        max-width: 90%;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .3;
        z-index: 1;
        background-color: $color-primary;
        transition: background-color 300ms ease-in-out;
      }

      .accordion-horizontal-toggle {
        z-index: 3;
        flex: 1;
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
        color: white;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        transition: opacity 100ms ease-in-out;

        @media only screen and (max-width:1200px) {
          opacity: 0 !important;
          pointer-events: none !important;
        }

        .accordion-headline {
          padding: 1rem;
          text-align: left;
          color: #fff;
          font-weight: 700;
          font-size: 1rem;
          width: 100%;
          text-align: center;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          word-break: break-word;
        }
      }

      .accordion-horizontal-content {
        @include responsive(phone) {
          padding: 50px;
        }
        @include responsive(landscape) {
          padding: 110px;
        }

        z-index: 2;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100px;
        @media only screen and (max-width:1200px) {
          width: auto;
        }
      }

      .ce_text {
        opacity: 0;
        transition-duration: 150ms;
        transition-property: opacity;
        transition-timing-function: ease-in;
        font-size: 0.9rem;
        @include responsive(phone) {
          font-size: 1rem;
        }

        @media only screen and (max-width:1200px) {
          opacity: 1 !important;
          padding: 2rem !important;
        }

        > .inside {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          .headline {
            @include responsive(phone) {
              font-size: 2rem;
            }
            font-size: 1.3rem;
            color: #fff;
          }
        }
      }
    }
  }

  &.image-fit-contain {
    .accordion_horizontal_container {
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .btn {
    margin-top: 20px;
  }
}

.ce_rsce_horizontal_boxes {
  +.ce_rsce_horizontal_boxes {
    margin-top: 0;
  }
}
