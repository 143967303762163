


//=======================> Startseite Header
#header {
	position: relative;
	// margin-bottom: $gap-default;
}


.header__start {
	position: relative;
	min-height: 570px;
	background-color: $color-primary;
	overflow: visible;
	@include responsive(phone) {
		min-height: 700px;
	}
	.header__inner {
		position:absolute;
		top: 60%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		.header__headline {
			display: flex;
			flex-direction: column;
			h1{
				color: #fff;
				font-size: 2.2rem;
				display: block;
				position: relative;
				margin:0;
				overflow: hidden;
				visibility: visible;
				font-size: 1.8rem;
				@include responsive (phone) {
					font-size: 2rem;
				}
				@include responsive (tablet) {
					font-size: 2rem;
				}
				@include responsive (landscape) {
					font-size: 2.2rem;
				}
				@include responsive (desktop) {
					font-size: 2.4rem;
				}
				@include responsive (extralarge) {
					font-size: 3.2rem;
				}
				span {
					display: block;
				}
			}
		}
	}
	.header__image {
		@include responsive(landscape) {
			position: absolute;
			width: 48vw;
			bottom: -100px;
			top: 135px;
			right: 0;
			height: 100%;
			display: block;
		}
		display: none;
		img {
			display: flex;
			width: 100%;
			object-fit: cover;
			height: 750px;
		}
	}
	.header__pattern-overlay {
		display: flex;
		position: absolute;
		bottom: 0;
		opacity: 1;
	}
}

.header__sub {
	position: relative;
	background: $color-primary;
	.header__headline {
		color: #fff;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: 50%;
		padding-bottom: 40px;
		h1{color: #fff; margin: 0}
	}
	img {
		width: 100%;
		display: flex;
	}
}

.main-navigation-wrapper {
	.logo {
		svg path, rect{
			fill: #fff;
		}
	}
}

.no-header-image {
	.header__sub {
		min-height: 500px;
	}
	.header__headline {
		padding-bottom: 40px;
	}
	.main-navigation-wrapper {
		background: none;
	}
}

//=====================================> End


.subheadline {
	color: $grey;
	font-size: 1.1rem;
	font-family: $font-headline;
	font-weight: 400;
	transform: translateY(-12px);
	display: inline-block;
	margin-top: 15px;
	@include responsive(phone) {
		font-size: 1.5rem;
	}
}