/* rozha-one-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Rozha One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rozha-one-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/rozha-one-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/rozha-one-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/rozha-one-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/rozha-one-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/rozha-one-v13-latin-regular.svg#RozhaOne') format('svg'); /* Legacy iOS */
}

/* Mundial Light */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mundial';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/mundial-light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/mundial-light.woff') format('woff'), /* Modern Browsers */
  url('../fonts/mundial-light.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Mundial Semibold */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mundial';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/mundial-demibold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/mundial-demibold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/mundial-demibold.ttf') format('truetype'); /* Safari, Android, iOS */
}
