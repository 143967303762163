.main-navigation-wrapper {
  position: absolute;
  padding-top: 20px;
  top: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	.container {
		position: relative;
	}
  .logo, .logo svg {
    max-width: 250px;
  }
  &.submenu--open {
    background: $color-primary;
    .level_1 span {
      color: #fff !important
    }
  }
}

//Main Navigation Level 1
.header__main-navigation {
  @include responsive(landscape) {
    display: flex;
    justify-content: flex-end;
  }
  overflow: visible;
  display: none;
  font-size: 1rem;
  position: unset;
  ul.level_1 {
    margin: 0;
    display: flex;
    justify-content: flex-end;
     li {
       transition: all .3s;
       &:hover > ul.level_2 {
         display: block;
       }
       a.sibling {
         position: relative;
         &:hover:after {
           content: "";
           width: 100%;
           height: 2px;
           position: absolute;
           z-index: 1;
           bottom: 0;
           right: 0;
           transition: width 0.2s;
           background-color: $color-primary;
           left: 0;
         }
         &:after {
           content: "";
           width: 0%;
           height: 2px;
           position: absolute;
           z-index: 1;
           bottom: 0;
           right: 0;
           transition: width 0.2s;
           background-color: $color-primary;
         }
       }
     }
    a {
      text-decoration: none;
      transition-duration: .2s;
    }
    span {
      color: #fff;
      position: relative;
      padding: 44px 20px;
      display: block;
    }
  }
  .level_2 {
    span {
      padding: 10px;
    }
  }
}

.header__main-navigation ul li.last span {
  padding-right: 0px;
}


.header__main-navigation ul.level_2 span {
  transition: transform .3s;
}

.header__main-navigation ul.level_2 li:hover span:not(.active) {
  color: $color-primary;
  transform: translateX(10px);
}

.header__main-navigation ul.level_2 span.active{
  color: $color-primary;
}



//====================Burger Icon

.mobile-navigation {
  @include responsive(landscape) {
    display: none;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2.2rem;
  svg {
    fill: #fff;
    width: 40px
  }
}

.mm-menu {
  background: $color-primary;
  color: #fff;
}
//====================End
.mm-title {
  color: #fff !important;
}

.mm-next:after, .mm-prev:before {
  border-color: #fff !important;
}

.mm_dropdown {
  transition: opacity .3s;
  opacity: 0;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  left: -99999px;
  right: 0;
  padding: 65px;
  box-shadow: $shadowed;
  .mm_dropdown__subnavigation {
    height: 100%;
  }
  &__contact-box {
    background: $grey;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    padding: 30px;
    overflow: hidden;
    font-size: 0.8rem;
    .ce_image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      figure, img {
        height: 100%;
      }
      img {
        object-fit: cover;
      }
    }
  }
  .level_2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
  a:not(.btn) {
    color: $color-dark;
    font-weight: $light;
    &:hover {
      color: darken(#fff, 30);
    }
  }
  p {
    text-transform: initial;
  }
  li {
    margin-bottom: 15px;
    display: block;
    align-items: center;
    font-size: 1.2rem;
    &:before {
      content: "";
      display: inline-block;
      background: get-icon('chevron-right', $color-primary) no-repeat;
      height: 15px;
      width: 15px;
      background-size: contain;
      margin-right: 10px;
    }
  }
}


.submenu {
  &:hover {
    .mm_dropdown {
      opacity: 1;
      left: auto;
    }
    .main-navigation-wrapper {
      background-color: $color-primary;
    }
  }
}


.top-bar {
  @include responsive(landscape) {
    display:flex;
    justify-content: flex-end;
  }
  display: none;
  font-size: 0.8rem;
  ul {
    display: flex;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

