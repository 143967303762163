/*========================Footer======================*/
#footer {
	color: #fff;
	font-size: 0.9rem;
	background-color: $color-primary;
	padding: 80px 0;
	.footer__wrapper {
		padding: 50px 0px 20px 0px;
	}
	.footer__links {
		h4 {font-size: 1.2rem; color: $color-tertiary;}
		ul {padding: 0}
	}
	.footer__social {
		display: flex;
		svg {
			height: 25px;
			width: 25px;
			margin: 10px 15px 0px 0px;
		}
	}
	h3 {
		color: #fff;
	}
	h4, h4 a {
		color: #000;
		font-weight: 700;
		text-transform: uppercase
	}
	a {
		color: $color-tertiary;
	}
}

.footer__divider {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
}

.footer__logo {
	display: block;
	margin-bottom: 25px;
	max-width: 180px;
	svg g {
		fill: #fff;
	}
}

.footer__divider__line {
	width: 40%;
	height: 2px;
	background: #fff;
	@include responsive(phone) {
		width: 45%;
		height: 3px;
	}
}

.footer__divider__image {
	width: 12%;
	@include responsive(phone) {
		width: 7%;
	}
	svg {
		width: 100%;
		display: flex;
		height: auto;
	}
}

.copyright-wrapper {
	padding-top: 20px;
	.copyright {
		font-size: 0.7rem;
	}
	.copyright__navigation {
		display: flex;
		justify-content: flex-start;
		margin-top: 25px;
		@include responsive(phone) {
			justify-content: flex-end;
			margin-top: 0px;
		}
		ul {
			display: flex;
			list-style: none;
			li:first-child {
				margin-right: 10px;
			}
		}
	}
	a {color: #fff}
}
