html {
	font-size: 19px;
}

body {
	background: #f3f3f3;
}


h1, h2 {
	font-family: $font-headline;
	margin-top: 0;
}
h1 {
	@include responsive(phone) {
		font-size: 3.2rem;
	}
	font-size: 2.2rem;
}

h2 {
	@include responsive (phone) {
		font-size: 2.6rem
	}
	margin-bottom: 10px;
	font-size: 2rem;
	color: $color-primary;
	line-height: 1.1;
	&.highlight:after {
		content: "";
		height: 5px;
		margin-top: 15px;
		margin-bottom: 25px;
		width: 30%;
		background: $color-secondary;
		display: block;
	}
}

h3 {
	font-size: 1.2rem;
	font-family: $font-stack-body;
	color: $color-primary;
	font-weight: $bold; 
}





.main {
	ul  {
		list-style-position: inside;
		list-style-type: disc;
		li {
			margin-bottom: 10px;
		}
	}
}

a {
	color: $color-primary;
	&:hover {
	text-decoration: none;
	color: darken($color-primary, 10%)
	}
}

.image {
	&__rounded {
		figure {
			display: flex;
			max-width: 160px;
			max-height: 160px;
			min-width: 100px;
			min-height: 100px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 5%;
		}
	}
}


figure {
	margin: 0;
	figcaption {
		font-size: 0.9rem;
		color: #9a9a9a;
	}
}

strong {
	font-weight:600;
}

//Listing
.listing--vertical {
	ul {
		li {
			margin-bottom: 10px;
			&:before {
				content: "";
				background: get-icon('rectangle', $color-secondary) no-repeat;
				height: 15px;
				width: 15px;
				background-size: contain;
				margin-right: 10px;
				display: inline-block;
				margin-right: 10px;
			}
		}
	}
}

.quote {
	background-image: url("/files/images/roses.png");
	background-size: 100%;
	min-height: 650px;
	background-repeat: no-repeat;
	background-position: bottom center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	blockquote {
		font-style: italic;
		position: relative;
		color: $color-secondary;
		text-align: center;
		&:before {
			content: "";
			background: get-icon('quote', $color-primary) no-repeat;
			display: inline-block;
			height: 60px;
			width: 60px;
			background-size: 100%;
		}
		p {
			@include responsive(phone) {
				font-size: 2.1rem;
			}
			max-width: 100%;
			font-size: 1.7rem;
			line-height: 1.5;
			margin: auto;
		}
		cite {
			margin-top: 20px;
			color: $color-dark;
			display: flex;
			align-items: center;
			&:before {
				content: "";
				background: get-icon('quote', $color-primary) no-repeat;
				display: inline-block;
				height: 20px;
				width: 20px;
			}
		}
	}
	figure figcaption{
		text-align: center;
		color: $color-primary;
	}
}

.container {
	max-width: $container-width;
	overflow: visible;
}

.shadowed {
	box-shadow: $shadowed;
	overflow: visible;
	display: inline-block
}


.highlight {
	h2 {
		margin-bottom: 25px;
		&::after {
			margin-top: 10px;
			content:"";
			width: 60px;
			height: 5px;
			display: block;
			background-color: $color-primary;
		}
	}
}

//gaps
.mt-default {
	@include responsive (landscape) {
		margin-top: $gap-default;
	}
	margin-top: $gap-default-mobile 
}

.mb-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default
	}
	margin-bottom: $gap-default-mobile
}

.my-default {
	@include responsive (landscape) {
		margin-bottom: $gap-default;
		margin-top: $gap-default
	}
	margin-bottom: $gap-default-mobile;
	margin-top: $gap-default-mobile
}

.py-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default;
		padding-top: $gap-default
	}
	padding-bottom: $gap-default-mobile;
	padding-top: $gap-default-mobile
}

.pt-default {
	@include responsive (landscape) {
		padding-top: $gap-default
	}
	padding-top: $gap-default-mobile
}
.pb-default {
	@include responsive (landscape) {
		padding-bottom: $gap-default
	}
	padding-bottom: $gap-default-mobile
}


/*========================Header======================*/




/*========================Main-Content======================*/


.row {
	&--flex {
		display: flex;
		& > * {
			display:flex; 
		}
	}
}

.shadowed {
    -webkit-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    -moz-box-shadow: 7px 9px 27px 6px rgba(0,0,0,0.15);
    box-shadow: 8px 5px 20px 1px rgba(0,0,0,0.15);
}

.block {
	&__visible {
		overflow: visible;
	}
}

.box {
	position: relative;
	overflow: visible;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-bottom: solid 10px $color-primary;
	box-shadow: $shadowed;
	margin-bottom: 40px;
	background: #fff;
	font-size: 0.9rem;
	&__bg {
		background-size: contain;
		background-repeat: no-repeat; 
		background-position: top center; 
	}
}


.linkbox {
	@include responsive (desktop) {
		margin: 0;
	}
	@include responsive (phone) {
		min-height: 600px;
	}
	min-height: 280px;
	overflow: hidden;
	position: relative;
	margin: 0 auto 20px auto;
	z-index: 1;
	opacity: 1;
	& > a {
		color: #fff;
		position: absolute;
		left: 0;
		right: 0;
		font-size: 0.9rem;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		&:hover {
			img {
				transform: scale(1.05);
				transition:all .8s ease;
			}
		}
		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(0deg,$color-primary,rgba($color-primary, 0));
			height: 100%;
			display: block;
			width: 100%;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
	h2 {
		color: #fff;
		padding: 40px;
		font-size: 1.5rem;
		position: absolute;
		z-index: 2;
	}
	&__teasertext {
		color: #fff;
		opacity: 0;
		position: absolute;
		bottom: -40px;
		padding: 40px;
	}
	svg {
		opacity: 0;
		position: absolute;
		right: 40px;
		bottom: 10%;
	}
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all .8s ease-in-out;
	}
}





.accordion {
	border-bottom: solid 1px #000;
	&__toggler {
		display: flex;
		justify-content: space-between;
		font-size: 1.5rem;
		padding: 20px 0;
		cursor: pointer;
		&:focus {outline: none}
	}
	&__content {
		//max-height: 0
		//transition: max-height 0.5s ease;
	}
	.ui-icon {
		display: none;
	}
	i {
		color: $color-primary;
	}
}


.news {
	&__latest {
		font-size: 0.9rem;
		&__inner {
			position: relative;
		}
		img {
			width: 100%;
			display: flex;
		}
		.ce_text {
			display: none;
		}
		h3 {
			font-size: 1.6rem;
			margin-top: 0;
		}
	}
	&__meta {
		display: flex;
		justify-content: space-between;
		display: none;
	}
	&__category {
		font-size: 13px;
		line-height: 18px;
		padding: 3px 8px; 
		color: $grey;
		border-radius: 2px;
		border: solid 1px #a3a3a3; 
	} 
	&__quotes {

		a {
			color: #fff;
		}
		h3 {
			margin: 0;
			font-size: 2rem;
			color: #fff;
		}
	}
	&__col {
		margin-bottom: 180px;
	}
	&__customer-title {
		font-weight: $bold;
		font-size: 1.45rem;
	}
}

.mod_newsreader .ce_text{
	max-width: 900px !important;
	margin: auto;
	margin-top: 20px;
}

.news__latest.even {
	figure {
		@include responsive(tablet) {
			margin-top: 60px;
		}
		margin-bottom: 20px;
		width: 100%;
	}
	.news__latest__overlay {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: #fff;
		padding: 20px;
	}
}


.quote {
	&__wrapper {
		position: relative;
		&:after {
			content: "";
			background: $grey;
			position: absolute;
			width: 55%;
			height: 100%;
			display: block;
			bottom: 0;
			right: 0;
			z-index: -1;
		}
	}
}



/*
*******************
** Subpages Styling
*******************
*/


.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	transition-timing-function:linear!important; //Wichtig für kontinuierliches scrolling
}



////=========================Traurig

.section {
	&__about {
		padding-top: 200px;
		padding-bottom: 120px;
		@include responsive(landscape) {

		}
		.image__breakout {
			transform: translate(110px, -110px);

		}
		.headline__breakout {
			@include responsive(desktop) {
				padding-top: 170px
			}
		}
		.text__breakout {
			@include responsive(desktop) {
				padding-top: 75px;
				padding-left: 40px;
			}
		}
	}
	&__contact {
		.textbox {
			@include responsive(landscape) {
				padding: 5vw;
			}
			@include responsive(extralarge) {
				padding: 5vw 15vw 5vw 5vw;
			}
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.imagebox img{
			height: 500px;
			width: 100%;
			object-fit: cover;
			@include responsive(landscape) {
				height: 760px;
			}
		}
	}

	&__service {

	}
	&__featured {
		position: relative;
	}
	&__benefits {
		.benefit {
			margin-bottom: 25px;
			img {
				margin: unset;
			}
		}
	}
}


.custom-heading {
	@include responsive (phone) {
		left: 0;
		width: 100%;
		transform: translateX(0);
	}
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;


	left: 50%;
	transform: translateX(-50%);
	width: 180%;
}



.service {
	&__wrapper {
		font-size: 0.85rem;
		svg {
			height: 65px;
			width: 100%;
			fill: $color-dark;
		}
		.ce_text {
			margin-bottom: 25px;
		}
	}
	&__list {
		background-color: $color-primary;
		color: #fff;
		position: relative;
		padding-top: 120px;
		@include responsive(landscape) {
			padding-top: 120px;
		}
		li {
			padding: 10px 0 25px 0;
			margin-bottom: 25px;
			font-size: 1.2rem;
			border-bottom: solid 2px #fff;
		}
		h2 {
			margin-bottom: 35px;
		}
		&:before {
			content: "";
			background: #f3f3f3;
			height: 80px;
			width: 80px;
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			transform: rotate(45deg);
			top: -40px;
		}
	}
	&__navigation {
		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			font-size: 1.2rem;
			@include responsive(phone) {
				font-size: 1.7rem;
			}
			@include responsive(landscape) {
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;
			}
			li:first-child {
				margin-bottom: 35px;
				@include responsive(landscape) {
					margin-bottom: 0px;
				}
			}
			span {
				&:first-child {
					margin-right: 10px;
				}
				&:last-child {
					margin-left: 10px;
				}
			}
		}
	}
}


.news {
	&__jobs {
		&__box {
			background: #fff;
			padding: 25px;
			font-size: $font-size-small;
			.image_container {
				margin-bottom: 20px;
			}
		}
	}
}

.team {
	&__breakout-box {
		@include responsive(phone) {
			padding: 0px;
		}
		@include responsive(landscape) {
			background-image: url("/files/images/icons/muehlbauer_team_typo.svg");
			background-position: right 50px;
			background-size: 95px 600px;
			background-repeat: no-repeat;
		}
		&:after {
			content: "";
			position: absolute;
			background: $color-primary;
			display: block;
			height: 65%;
			top: 0;
			z-index: -1;
			left: 0;
			right: calc(50vw - 400px);
			width: auto;
		}
		&__text {
			color: #fff;
			padding-top: 50px;
		}
		h3 {
			color: $grey;
			font-size: 1.5rem;
			font-family: $font-headline;
			font-weight: 400;
		}
	}
	&__breakout-container {
		position: relative;
		padding: 0px;
		margin-top: -40px;
		z-index: 1;
	}
	&__swiper {
		display: block;
		margin-bottom: 40px;
		@include responsive(tablet) {
			display: none;
		}
	}
	&__row {
		display: none;
		@include responsive(tablet) {
			display: flex;
		}
	}
}

.ce_gallery {
	.image_container {
	}
	.row {
		justify-content: center;
		align-items: center;
	}
}

.social_feed_element {
	.inner {
		border-top: none;
		background: none;
		.ce_text {
			display: none;
		}
	}
	.info {
		display: none;
	}
}


.bg-pattern {
	background-image: url("/files/images/icons/muehlbauer-pattern.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom left;
}


//======OpenStreetMap//
.c4g-popup-wrapper {
	border-radius: 0 !important;
	padding: 25px !important;
}

//Team
.team__portrait {
	margin-bottom: 30px;
	p {
		padding: 15px 0;
	}
}

//======EUF Overlay//
.euf_overlay__content {
	padding: 0px;
	display: flex;
	max-width: 470px;
}

.euf_overlay__close {
	color: #fff;
}


//Badge
.muehlbauer-badge {
	width: 120px;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0;
	@include responsive(tablet) {
		width: 180px;
	}
	svg {
		width: 100%;
		display: block;
		height: auto;
		overflow: visible;
	}
}


.muehlbauer-badge .outer{
	animation: rotate infinite 8s linear;
	transform-origin: center center;
}

.banner-divider {
	background-image: url("/files/images/upload/CR_Steuerkanzlei_Mühlbauer_21092022_027.jpg");
	background-size: cover;
	background-attachment:  fixed;
	background-repeat: no-repeat;
	min-height: 520px;
}

.reveal {
	visibility: hidden;
	overflow: hidden;
}


.phone-number {
	font-size: 1.5rem;
}

.typewriter {
	h2 {
		font-size: 3.5rem;
	}
}

